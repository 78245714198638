/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
/*more*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ol: "ol",
    li: "li",
    pre: "pre",
    code: "code",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The new rich text type is now live and being used in Quill v0.18.0. It is a big step towards 1.0 and will be the way documents and changes are represented going forward. In most cases this update is non-disruptive and an upgrade can be a simple increment of the version number[^1]."), "\n", React.createElement(_components.p, null, "However, if you happened to be storing the old Delta format, here's a short guide on how to migrate."), "\n", React.createElement(_components.p, null, "The main relevant differences between the old and new Deltas are:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Explicit deletes - We need to go through the old Delta, find the implied deletes and insert explicit delete operations into the new Delta"), "\n", React.createElement(_components.li, null, "Support for embeds - If we see the hacky representation of embeds, replace with the new representation"), "\n"), "\n", "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "var richText = require('rich-text');\n\nvar newDelta = new richText.Delta();\nvar index = 0;\nvar changeLength = 0;\noldDelta.ops.forEach(function (op) {\n  if (_.isString(op.value)) {\n    // Insert operation\n    if (op.value === '!' && op.attributes && _.isString(op.attributes.src)) {\n      // Found the old hacky representation for an embed\n      // Quill only supports images so far so we can be confident this is an image\n      // which is represented by 1\n      newDelta.insert(1, op.attributes);\n    } else {\n      newDelta.insert(op.value, op.attributes);\n    }\n    changeLength += op.value.length;\n  } else if (_.isNumber(op.start) && _.isNumber(op.end)) {\n    // Retain operation\n    if (op.start > index) {\n      // Delete operation was implied by the current retain operation\n      var length = op.start - index;\n      newDelta.delete(length);\n      changeLength -= length;\n    }\n    // Now handle or retain operation\n    newDelta.retain(op.end - op.start, op.attributes);\n    index = op.end;\n  } else {\n    throw new Error('You have a misformed delta');\n  }\n});\n\n// Handle implied deletes at the end of the document\nif (oldDelta.endLength < oldDelta.startLength + changeLength) {\n  var length = oldDelta.startLength + changeLength - oldDelta.endLength;\n  newDelta.delete(length);\n}\n")), "\n", React.createElement(_components.p, null, "If you cannot use the rich-text module or if you are using this as a general guide for another language, the following might be helpful in crafting insert, delete and retain operations."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "var nweDelta = { ops: [] };\noldDelta.ops.forEach(function () {\n  // Following a similar logic to the earlier snippet\n  // except replacing .insert(), .retain(), .delete() with:\n  // insertOp(newDelta.ops, value, formats)\n  // retainOp(newDelta.ops, length, formats)\n  // deleteOp(newDelta.ops, length)\n});\n\nfunction insertOp(opsArr, text, formats) {\n  var op = { insert: text };\n  if (formats && Object.keys(formats).length > 0) {\n    op.attributes = formats;\n  }\n  opsArr.push(op);\n}\n\nfunction deleteOp(opsArr, length) {\n  opsArr.push({ delete: length });\n}\n\nfunction retainOp(opsArr, length, formats) {\n  var op = { retain: length };\n  if (formats && Object.keys(formats).length > 0) {\n    op.attributes = formats;\n  }\n  opsArr.push(op);\n}\n")), "\n", React.createElement(_components.p, null, "There are some optimizations performed by rich-text such as excluding no-ops (delete 0 characters) and merging two adjacent operations of the same type (insert 'A' followed by insert 'B' is merged to be a single insert 'AB' operation). But you should not have to worry about these cases since the old Delta format had similar optimizations."), "\n", React.createElement(_components.p, null, "[^1]: All it took to upgrade the examples on quilljs.com was: ", React.createElement(_components.a, {
    href: "https://github.com/quilljs/quill/commit/2580c2a5d440622d226fbef407df7a5a5e9dcf61"
  }, "2580c2")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
